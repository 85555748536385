import {HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {APP_ID, ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
  provideClientHydration, withHttpTransferCacheOptions
} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {SsrCookieService} from 'ngx-cookie-service-ssr';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ToastrModule} from 'ngx-toastr';
import {ApiModule, BASE_PATH} from './app/api';
import {AppRoutingModule} from './app/app-routing.module';
import {ErrorInterceptor} from './app/interceptors/error.interceptor';
import {JwtInterceptor} from './app/interceptors/jwt.interceptor';
import {environment} from './environments/environment';
import {provideServiceWorker} from '@angular/service-worker';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';


export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: false,
        filter: request => !request.url.includes('/footer-statistics/getData') // exclude footer get
      }),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    importProvidersFrom(
      ApiModule,
      BrowserModule,
      AppRoutingModule, // required animations module
      SsrCookieService,
      ToastrModule.forRoot(), // ToastrModule added
      HammerModule,
      ImageCropperModule,
      GoogleTagManagerModule.forRoot({
        id: environment.gtagId,
      })
    ),
    // {provide: 'googleTagManagerId', useValue: environment.gtagId},
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    },
    {
      provide: BASE_PATH,
      useValue: environment.apiPath,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT'
    },
    {
      provide: APP_ID,
      useValue: 'serverApp'
    },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAnimations(),
  ]
};
