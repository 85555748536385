import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authenticationServive: AuthenticationService,
    @Inject(PLATFORM_ID) private platformId: any,
    ) {

  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authenticationServive.token;
    if (token) {
      request = request.clone({ setHeaders: { Authorization: `${token}`, } });
    }
    return next.handle(request);
  }

}
