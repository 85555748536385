import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./route-modules/home/home.module').then(m => m.HomeModule),
  },

  // IFRAME AGENZIA
  {
    path: 'i/:id',
    loadChildren: () => import('./route-modules/agency-iframe/agency-iframe.module').then(m => m.AgencyIframeModule),
  },

  // PAGINE DI RICERCA: AGENTE, AGENZIA E ANNUNCIO
  {
    path: 'vendita',
    loadChildren: () => import('./route-modules/ricerca-component/ricerca-component.module').then(m => m.RicercaComponentModule),
  },
  {
    path: 'affitto',
    loadChildren: () => import('./route-modules/ricerca-component/ricerca-component.module').then(m => m.RicercaComponentModule),
  },
  {
    path: 'agenzia',
    loadChildren: () => import('./route-modules/ricerca-component/ricerca-component.module').then(m => m.RicercaComponentModule),
  },
  {
    path: 'agente',
    loadChildren: () => import('./route-modules/ricerca-component/ricerca-component.module').then(m => m.RicercaComponentModule),
  },
  {
    path: 'professionista',
    loadChildren: () => import('./route-modules/ricerca-component/ricerca-component.module').then(m => m.RicercaComponentModule),
  },

  // PAGINE RICERCA ATECO
  // {
  //   path: 'ricerca-ateco/:query',
  //   loadChildren: () => import('./route-modules/ricerca-ateco/ricerca-ateco.module').then(m => m.RicercaAtecoModule),
  // },

  // PAGINE DETTAGLIO ANNUNCIO, AGENZIA, AGENTE
  {
    path: 'dettaglio-annuncio-multiplo/:id',
    loadChildren: () => import('./route-modules/dettaglio-annuncio/dettaglio-annuncio.module').then(m => m.DettaglioAnnuncioModule),
  },
  {
    path: 'in-vendita/:id',
    loadChildren: () => import('./route-modules/dettaglio-annuncio/dettaglio-annuncio.module').then(m => m.DettaglioAnnuncioModule),
  },
  {
    path: 'in-asta/:id',
    loadChildren: () => import('./route-modules/dettaglio-annuncio/dettaglio-annuncio.module').then(m => m.DettaglioAnnuncioModule),
  },
  {
    path: 'venduto/:id',
    loadChildren: () => import('./route-modules/dettaglio-annuncio/dettaglio-annuncio.module').then(m => m.DettaglioAnnuncioModule),
  },
  {
    path: 'in-affitto/:id',
    loadChildren: () => import('./route-modules/dettaglio-annuncio/dettaglio-annuncio.module').then(m => m.DettaglioAnnuncioModule),
  },
  {
    path: 'affittato/:id',
    loadChildren: () => import('./route-modules/dettaglio-annuncio/dettaglio-annuncio.module').then(m => m.DettaglioAnnuncioModule),
  },
  {
    path: 'annuncio/:id',
    loadChildren: () => import('./route-modules/dettaglio-annuncio/dettaglio-annuncio.module').then(m => m.DettaglioAnnuncioModule),
  },
  {
    path: 'dettaglio-agenzia-ateco/:id',
    loadChildren: () => import('./route-modules/dettaglio-agenzia-ateco/dettaglio-agenzia-ateco.module').then(m => m.DettaglioAgenziaAtecoModule),
  },
  {
    path: 'dettaglio-agenzia/:id',
    loadChildren: () => import('./route-modules/dettaglio-agenzia/dettaglio-agenzia.module').then(m => m.DettaglioAgenziaModule),
  },
  {
    path: 'dettaglio-agente-ateco/:id',
    loadChildren: () => import('./route-modules/dettaglio-agente-ateco/dettaglio-agente-ateco.module').then(m => m.DettaglioAgenteAtecoModule),
  },
  {
    path: 'dettaglio-agente/:id',
    loadChildren: () => import('./route-modules/dettaglio-agente/dettaglio-agente.module').then(m => m.DettaglioAgenteModule),
  },
  {
    path: 'recensione-agente/:id',
    loadChildren: () => import('./route-modules/recensione-agente/recensione-agente.module').then(m => m.RecensioneAgenteModule),
  },
  {
    path: 'dettaglio-brand/:id',
    loadChildren: () => import('./route-modules/dettaglio-brand/dettaglio-brand.module').then(m => m.DettaglioBrandModule),
  },
  {
    path: 'prodotto/:id',
    loadChildren: () => import('./route-modules/dettaglio-prodotto/dettaglio-prodotto.module').then(m => m.DettaglioProdottoModule),
  },
  {
    // route prodotto consulente
    path: 'prodotto/:id/:id',
    loadChildren: () => import('./route-modules/dettaglio-prodotto/dettaglio-prodotto.module').then(m => m.DettaglioProdottoModule),
  },
  {
    path: 'consulente-credito-mutui-prestiti-assicurazioni/:id',
    loadChildren: () => import('./route-modules/dettaglio-professionista/dettaglio-professionista.module').then(m => m.DettaglioProfessionistaModule),
  },
  {
    path: 'recensione-consulente-credito-mutui-prestiti-assicurazioni/:id',
    loadChildren: () => import('./route-modules/recensione-professionista/recensione-professionista.module').then(m => m.RecensioneProfessionistaModule),
  },

  // route news
  {
    path: 'news',
    loadChildren: () => import('./route-modules/news/news.module').then(m => m.NewsModule),
  },
  {
    path: 'news/ricerca',
    loadChildren: () => import('./route-modules/news-ricerca/news-ricerca.module').then(m => m.NewsRicercaModule),
  },
  {
    path: 'news/:id',
    loadChildren: () => import('./route-modules/news-dettaglio/news-dettaglio.module').then(m => m.NewsDettaglioModule),
  },

  //  WHITE LABELS
  {
    path: 'auxilia',
    loadChildren: () => import('./route-modules/white-labels/white-labels.module').then(m => m.WhiteLabelsModule),
  },

  {
    path: 'bnl',
    loadChildren: () => import('./route-modules/white-labels/white-labels.module').then(m => m.WhiteLabelsModule),
  },

  // PAGINE PROFILO
  {
    path: 'utente',
    loadChildren: () => import('./route-modules/utente-modifica/utente-modifica.module').then(m => m.UtenteModificaModule),
  },
  {
    path: 'utente-annunci',
    loadChildren: () => import('./route-modules/utente-annunci/utente-annunci.module').then(m => m.UtenteAnnunciModule),
  },
  {
    path: 'utente-agenti',
    loadChildren: () => import('./route-modules/utente-agenti/utente-agenti.module').then(m => m.UtenteAgentiModule),
  },
  {
    path: 'utente-professionisti',
    loadChildren: () => import('./route-modules/utente-professionisti/utente-professionisti.module').then(m => m.UtenteProfessionistiModule),
  },
  {
    path: 'utente-prodotti',
    loadChildren: () => import('./route-modules/utente-prodotti/utente-prodotti.module').then(m => m.UtenteProdottiModule),
  },
  {
    path: 'utente-agenzie',
    loadChildren: () => import('./route-modules/utente-agenzie/utente-agenzie.module').then(m => m.UtenteAgenzieModule),
  },
  {
    path: 'utente-ricerche',
    loadChildren: () => import('./route-modules/utente-ricerche/utente-ricerche.module').then(m => m.UtenteRicercheModule),
  },

  // RECUPERA PASSWORD
  {
    path: 'reset-password',
    loadChildren: () => import('./route-modules/recupera-password/recupera-password.module').then(m => m.RecuperaPasswordModule),
  },

  // PAGINE STAMPA
  {
    path: 'stampa-annuncio/:id',
    loadChildren: () => import('./route-modules/stampa-annuncio/stampa-annuncio.module').then(m => m.StampaAnnuncioModule),
  },
  {
    path: 'stampa-agenzia/:id',
    loadChildren: () => import('./route-modules/stampa-agenzia/stampa-agenzia.module').then(m => m.StampaAgenziaModule),
  },
  {
    path: 'stampa-agente/:id',
    loadChildren: () => import('./route-modules/stampa-agente/stampa-agente.module').then(m => m.StampaAgenteModule),
  },

  // PAGINE STATICHE
  {
    path: 'chi-siamo',
    loadComponent: () => import('./pages/chi-siamo/chi-siamo.component').then(m => m.ChiSiamoComponent),
  },
  {
    path: 'contattaci',
    loadComponent: () => import('./pages/contattaci/contattaci.component').then(m => m.ContattaciComponent),
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./pages/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
  },
  {
    path: 'privacy-policy-app',
    loadComponent: () => import('./pages/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
  },
  {
    path: 'cookie-policy',
    loadComponent: () => import('./pages/cookie-policy/cookie-policy.component').then(m => m.CookiePolicyComponent),
  },
  {
    path: 'condizioni-uso',
    loadComponent: () => import('./pages/condizioni-uso/condizioni-uso.component').then(m => m.CondizioniUsoComponent),
  },

  // ANNUNCI VISITATI (NOT LOGGED)
  {
    path: 'annunci-visitati',
    loadChildren: () => import('./route-modules/ricerche-recenti-sloggato/ricerche-recenti-sloggato.module').then(m => m.RicercheRecentiSloggatoModule),
  },

  // 404
  {
    path: 'pagina-non-trovata',
    loadChildren: () => import('./route-modules/not-found/not-found.module').then(m => m.NotFoundModule),
  },

  { path: '**', redirectTo: '/pagina-non-trovata', data: { showInMenu: false } }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      preloadingStrategy: NoPreloading
    }),
  ],
  providers: [CurrencyPipe],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
