import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {
  enableProdMode,
} from '@angular/core';
import {
  bootstrapApplication
} from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import 'hammerjs';
import { appConfig } from './app.config';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeIt);
bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));


