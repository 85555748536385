import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isPlatformBrowser(this.platformId)) {
      return next.handle(request).pipe(catchError(err => {
        if (err.status === 401) {
          if (request.url.endsWith('login') || request.url.endsWith('logout')) {
            return throwError(err.error.message || err.statusText);
          }
          this.authenticationService.logout().then(response => {
            return err;
          });
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      }));
    }
    return next.handle(request);
  }
}
